<template>
	<div class="editor" v-loading="loading">
		<tinymce v-model="text" model-events="change" :init="initOptions" tinymce-script-src="/tinymce/tinymce.min.js"
 />
		<br>
		<template v-if="editor">
			<el-dialog :visible.sync="dialogVisible" append-to-body>
				<div class="center">
					<tinymce-image @change="uploadImageSuccess" />
				</div>
			</el-dialog>
		</template>
	</div>
</template>

<script>
import querystring from "querystring";
export default {
	components: {
		Tinymce: () => import("@tinymce/tinymce-vue"),
		TinymceImage: () => import("@/components/tinymce-image")
	},
	model: {
		prop: "model",
		event: "change"
	},
	props: ["model"],
	data() {
		return {
			loading: true,
			dialogVisible: false,
			editor: undefined,
			initOptions: {
        language: 'zh-Hant',
        language_url: "/tinymce/langs/zh-Hant.js",
				plugins: "autoresize print preview fullpage importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
				toolbar: ["undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | forecolor backcolor | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist | insertImage | pagebreak | charmap emoticons | fullscreen preview"],
				font_family_formats: "Noto Serif TC;Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Open Sans=open sans,sans-serif; Roboto=roboto,regular,sans-serif",
				content_css: [
					// "/css/tinymce.css",
					"//fonts.googleapis.com/css?family=Noto+Serif+TC:500,700&display=swap&subset=chinese-traditional"
				],
				min_height: 500,
				statusbar: false,
				init_instance_callback: this.onInit,
				setup: editor => {
					editor.on("init", e => {
						this.loading = false;
						this.$emit("loaded");
					});
					editor.ui.registry.addButton("insertImage", {
						icon: "image",
						text: "插入圖片",
						tooltip: "插入圖片",
						onAction: () => {
							this.dialogVisible = true;
						}
					});
					editor.ui.registry.addButton("insertYoutube", {
						icon: "gamma",
						text: "插入Youtube",
						tooltip: "插入Youtube",
						onAction: () => {
							this.$prompt("請輸入Youtube網址", {
								inputPattern: /\?v/,
								inputErrorMessage: "請於 Youtube 網址列複製，貼於下方"
							})
								.then(({ value }) => {
									const { v } = querystring.parse(new URL(value).searchParams.toString());
									this.editor.insertContent(`<p></p><div class="youtube"><iframe src="https://www.youtube.com/embed/${v}" frameborder="0" allowfullscreen></iframe></div><p></p><br>`);
								})
						}
					});
				}
			}
		};
	},
	computed: {
		text: {
			get() {
				return this.model || "";
			},
			set(val) {
				this.$emit("change", val);
			}
		},
	},
	methods: {
		onInit(editor) {
			this.editor = editor;
		},
		uploadImageSuccess(res) {
			this.dialogVisible = false;
			if (this.editor) this.editor.insertContent(`<img src="${res.url}" style="max-width:100%; height:auto;">`);
		},
	}
};
</script>
<style lang="scss">
.tox .tox-promotion-link {
  display: none !important;
}
</style>